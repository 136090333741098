<template>
  <div>
    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="row my-3">
        <div class="col-12 col-md-3 offset-md-9">
          <button type="button" class="btn btn-primary waves-effect waves-light form-control" @click="GenerateList">
            <i class="mdi mdi-view-list"></i> リスト作成
          </button>
        </div>
      </div>

      <div v-if="!isList">
        <code v-if="listErrMsg">{{ listErrMsg }}</code>
      </div>

      <div v-else-if="jsonErr">
        <code>{{ apiError.message }}</code>
      </div>

      <div class="responsive-table-plugin" v-else>
        <div class="table-rep-plugin" v-if="movingCases.length">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th colspan="7"><div class="badge badge-success">移転前</div></th>
                  <th colspan="7"><div class="badge badge-info">移転後</div></th>
                </tr>
                <tr>
                  <th class="table-w-s"><div class="d-flex align-items-center">No</div></th>
                  <th class="table-w-l">テナント名</th>
                  <th class="table-w-l">業種</th>
                  <th class="table-w-m">移転時期</th>
                  <th class="table-w-l">移転理由</th>
                  <th class="table-w-l">ビル名</th>
                  <th class="table-w-l">所在地</th>
                  <th class="table-w-m">竣工</th>
                  <th class="table-w-m">延床</th>
                  <th class="table-w-m">基準階</th>
                  <th class="table-w-m">入居階</th>
                  <th class="table-w-m">使用面積</th>
                  <th class="table-w-l">ビル名</th>
                  <th class="table-w-l">所在地</th>
                  <th class="table-w-m">竣工</th>
                  <th class="table-w-m">延床</th>
                  <th class="table-w-m">基準階</th>
                  <th class="table-w-m">入居階</th>
                  <th class="table-w-m">使用面積</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(movingCase, num) in movingCases" :key="num">
                  <td>
                    <div class="table-data-container">
                      {{ num + 1 }}
                    </div>
                  </td>
                  <td>
                    <div class="table-data-container">
                      {{ movingCase.name }}
                    </div>
                  </td>
                  <td>
                    <div class="table-data-container">
                      {{ movingCase.industry }}
                    </div>
                  </td>
                  <td>
                    <div class="table-data-container">
                      {{ movingCase.relocation_date }}
                    </div>
                  </td>
                  <td>
                    <div class="table-data-container">
                      {{ movingCase.reason }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.before_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.before_relocation.building_name }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.before_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.before_relocation.location }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.before_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.before_relocation.completion_date }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.before_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.before_relocation.total_floor_area }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.before_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.before_relocation.standard_floor_area }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.before_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.before_relocation.floor }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.before_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.before_relocation.used_area }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.after_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.after_relocation.building_name }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.after_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.after_relocation.location }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.after_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.after_relocation.completion_date }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.after_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.after_relocation.total_floor_area }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.after_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.after_relocation.standard_floor_area }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.after_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.after_relocation.floor }}
                    </div>
                  </td>
                  <td :class="{ 'moving-target-cell': movingCase.after_relocation.target_area }">
                    <div class="table-data-container">
                      {{ movingCase.after_relocation.used_area }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <code>条件に一致するデータが見つかりません。</code>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'MovingList',
  props: {
    movingData: Array
  },
  data() {
    return {
      isLoading: true,
      isList: true,
      listErrMsg: '',
      jsonErr: false,
      movingCases: [
        {
          name: "",
          industry: "",
          relocation_date: "",
          reason: "",
          before_relocation: {
            target_area: null,
            building_name: "",
            location: "",
            completion_date: "",
            total_floor_area: "",
            standard_floor_area: "",
            floor: "",
            used_area: ""
          },
          after_relocation: {
            target_area: null,
            building_name: "",
            location: "",
            completion_date: "",
            total_floor_area: "",
            standard_floor_area: "",
            floor: "",
            used_area: ""
          }
        }
      ],
      apiError: {},
    }
  },
  mounted() {
    if( ((this.movingData[0].prefecture && this.movingData[0].prefecture !== ' ') && ( this.movingData[0].city && this.movingData[0].city !== ' ') && ( this.movingData[0].town && this.movingData[0].town !== ' ')) || ( this.movingData[0].omr_zone && this.movingData[0].omr_zone !== ' ')) {
      this.jsonErr = false
      // usonars
      axios
        .post('/v1/movingcase/search', {
          areas_of_list: this.movingData
        })
        .then(response => {
            this.movingCases = response.data.series
            this.$nextTick(() => {
              window.parent.postMessage(['loaded'], "*");
            })
          }
        )
        .catch(error => {
          this.jsonErr = true
          this.apiError = error.response.data
          }
        );
    } else {
      this.isList = false
      if( this.movingData[0].prefecture || this.movingData[0].city || this.movingData[0].town ) {
        this.listErrMsg = '都道府県、市区、町をすべて選択してください。'
      }
    }
    this.$nextTick(() => {
      this.isLoading = false
      window.parent.postMessage(['loaded'], "*");
    })
  },
  methods: {
    GenerateList: function() {
      if( ((this.movingData[0].prefecture && this.movingData[0].prefecture !== ' ') && ( this.movingData[0].city && this.movingData[0].city !== ' ') && ( this.movingData[0].town && this.movingData[0].town !== ' ')) || ( this.movingData[0].omr_zone && this.movingData[0].omr_zone !== ' ')) {
        this.isList = true

        this.jsonErr = false
        axios
          .post('/v1/movingcase/search', {
            areas_of_list: this.movingData
          })
          .then(response => {
              // console.log(response.data.series)
              this.movingCases = response.data.series
              this.$nextTick(() => {
                window.parent.postMessage(['loaded'], "*");
              })
            }
          )
          .catch(error => {
            this.jsonErr = true
            this.apiError = error.response.data
            }
          );
      } else {
        this.movingCases = []
        this.isList = false
        if( this.movingData[0].prefecture || this.movingData[0].city || this.movingData[0].town ) {
          this.listErrMsg = '都道府県、市区、町をすべて選択してください。'
        } else {
          this.listErrMsg = '対象誘致エリアは必須入力です。'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table thead {
  border-bottom: 2px solid #dee2e6;
}

.table thead tr th {
  border-bottom: none;
}

.table thead th {
  border-bottom: none;
  padding: {
    bottom: 5px;
    top: 5px;
  }
  white-space: nowrap;
  & .badge {
    display: block;
    line-height: 1.5;
    text-align: left;
  }
}

.table.table-striped td {
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped th:first-child,
.table.table-striped td:first-child {
  max-width: 2rem;
}

.table-w-s {
  min-width: 4rem;
}

.table-w-m {
  min-width: 8.5rem;
}

.table-w-l {
  min-width: 17.5rem;
}

.moving-target-cell {
  background: #e3e5fc;
  border-top-color: rgba(0, 0, 0, .1);
}
</style>