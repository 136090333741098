<template>

  <div class="row">
    <div class="col-12">
      <div class="card-box">
        <h2 class="header-title mb-3">4. 基本パラメータリスト</h2>

        <div class="responsive-table-plugin">
          <div class="table-rep-plugin">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th><div class="d-flex align-items-center">No</div></th>
                    <th>分野</th>
                    <th>キーワード</th>
                    <th>概要</th>
                    <th>調べ方</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(row, num) in params" :key="num">
                    <td>
                      <div class="table-data-container">
                        {{ row.no }}
                      </div>
                    </td>
                    <td>
                      <div class="table-data-container">
                        <input type="text" class="form-control" v-model="row.field">
                      </div>
                    </td>
                    <td>
                      <div class="table-data-container">
                        <input type="text" class="form-control" v-model="row.keyword">
                      </div>
                    </td>
                    <td>
                      <div class="table-data-container">
                        <input type="text" class="form-control" v-model="row.summary">
                      </div>
                    </td>
                    <td>
                      <div class="table-data-container">
                        <input type="text" class="form-control" v-model="row.find_out">
                      </div>
                    </td>
                    <td>
                      <button type="button" class="btn btn-danger waves-effect waves-light" @click="DelParamRow(num)"><i class="mdi mdi-minus"></i></button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6">
                      <button type="button" class="btn btn-blue waves-effect waves-light" @click="AddParamRow"><i class="mdi mdi-plus"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ParamList',
  props: {
    parameters: Object
  },
  data() {
    return {
    }
  },
  methods: {
    DelParamRow: function(val) {
      let last = Number(this.params.slice(-1)[0].no)

      this.params.splice(val,1)

      // 0件になる場合は空行を追加して1件に戻す
      if(this.params.length === 0) {
        this.params.push(
          {
            no: 1,
            field: "",
            keyword: "",
            summary: "",
            find_out: ""
          }
        )
      }

      for( let i = val; i <= last; i++ ) {
        this.params[i].no = i + 1
      }

      this.$nextTick(() => {
        window.parent.postMessage(['loaded'], "*");
      })
    },
    AddParamRow: function() {
      if( this.params.length ) {
        let last = Number(this.params.slice(-1)[0].no)
        this.params.push(
          {
            no: last + 1,
            field: "",
            keyword: "",
            summary: "",
            find_out: ""
          }
        )
      } else {
        this.params.push(
          {
            no: 1,
            field: "",
            keyword: "",
            summary: "",
            find_out: ""
          }
        )
      }
      this.$nextTick(() => {
        window.parent.postMessage(['loaded'], "*");
      })
    }
  },
  computed: {
    params: {
      get() {
        return this.parameters
      },
      set(params) {
        this.$emit('update:parameters', params)
      }
    }
  }
}
</script>