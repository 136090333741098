<template>
  <div>
    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="row mt-3">
        <div class="col-12 col-md-3 offset-md-9">
          <button type="button" class="btn btn-primary waves-effect waves-light form-control" @click="GenerateGraph">
            <i class="mdi mdi-chart-bar"></i> グラフ作成
          </button>
        </div>
      </div>
      
      <div v-if="!isChart">
        <code v-if="chartErrMsg">{{ chartErrMsg }}</code>
      </div>

      <div v-else-if="jsonErr">
        <code>{{ apiError.message }}</code>
      </div>
      
      <div v-else>
        <apexchart :height="chartHeight" width="100%" type="bar" :options="options" :series="series"></apexchart>
        <ul class="chart-score bg-light text-secondary">
          <li v-for="(area, num) in areas" :key="num">
            <span>住所{{num + 1}} : </span>
            <span v-if="area.omr_zone">{{area.omr_zone}}</span>
            <span v-else>{{area.prefecture + area.city + area.town}}</span>
            <span>({{area.cnt}})</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'BarChart',
  props: {
    barData: Object
  },
  data() {
    return {
      isLoading: true,
      isChart: true,
      chartErrMsg: '',
      jsonErr: false,
      chartHeight: '540px',
      options: {
        chart: {
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '65%', //グラフの縦幅
          }
        },
        xaxis: {
          categories: ['住所1','住所2','住所3'],
          labels: {
            formatter: function (val) {
              return Math.abs(Math.round(val)) + "%"
            }
          }
        },
        colors: [
          'rgba(63, 81, 181, 0.85)', 'rgba(3, 169, 244, 0.85)', 'rgba(76, 175, 80, 0.85)', 'rgba(249, 206, 29, 0.85)', 'rgba(80, 120, 90, 0.85)', 
          'rgba(181, 63, 81, 0.85)', 'rgba(200, 5, 140, 0.85)', 'rgba(140, 96, 200, 0.85)', 'rgba(29, 120, 206, 0.85)', 'rgba(222, 80, 152, 0.85)', 
          'rgba(81, 181, 12, 0.85)', 'rgba(169, 80, 3, 0.85)', 'rgba(11, 80, 76, 0.85)', 'rgba(206, 136, 55, 0.85)', 'rgba(152, 0, 255, 0.85)', 
          'rgba(201, 40, 5, 0.85)', 'rgba(10, 220, 151, 0.85)', 'rgba(100, 175, 35, 0.85)', 'rgba(150, 15, 105, 0.85)', 'rgba(20, 2, 200, 0.85)', 
          'rgba(252, 4, 252, 0.85)', 'rgba(169, 3, 244, 0.85)', 'rgba(80, 206, 169, 0.85)', 'rgba(29, 249, 29, 0.85)', 'rgba(150, 150, 150, 0.85)', 
        ],
        dataLabels: {
          style: {
            fontSize: '12px',
            fontWeight: 'normal',
          }
        },
      },
      series: [], // グラフ用データ
      areas: [], // エリア情報と取得件数
      apiError: {},
    }
  },
  created() {
    if( this.barData.length === 0 ) {
      this.series = []
    } else {
      this.isChart = true
      // 未入力の行があったらグラフを出さない
      this.barData.forEach(data => {
        if( !(((data.prefecture && data.prefecture !== ' ') && ( data.city && data.city !== ' ') && ( data.town && data.town !== ' ')) || ( data.omr_zone && data.omr_zone !== ' ')) ) {
          this.isChart = false
          if( data.prefecture || data.city || data.town ) {
            this.chartErrMsg = '都道府県、市区、町をすべて選択してください。'
          }
        }
      })

      if( this.isChart ) {

        if( (this.barData[0].prefecture && this.barData[0].city && this.barData[0].town) || this.barData[0].omr_zone ) {
          this.options.xaxis.categories = [];
          for( let i = 1; i <= this.barData.length; i++ ) {
            this.options.xaxis.categories.push('住所' + i)
          }
          this.chartHeight = 180 * this.barData.length + 'px'

          this.jsonErr = false
          // usonars
          axios
            .post('/v1/usonars/statistics', {
              areas_of_graph: this.barData
            })
            .then(response => (
                this.series = response.data.series,
                this.areas = response.data.areas
              )
            )
            .catch(error => {
              this.jsonErr = true
              this.apiError = error.response.data
              }
            );
          }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isLoading = false
      window.parent.postMessage(['loaded'], "*");
    })
  },
  methods: {
    GenerateGraph: function() {
      
      // エリア指定なし
      if( this.barData.length === 0 ) {
        this.series = []
      } else {
        this.isChart = true
        // 未入力の行があったらグラフを出さない
        this.barData.forEach(data => {
          if( !(((data.prefecture && data.prefecture !== ' ') && ( data.city && data.city !== ' ') && ( data.town && data.town !== ' ')) || ( data.omr_zone && data.omr_zone !== ' ')) ) {
            this.isChart = false
            if( data.prefecture || data.city || data.town ) {
              this.chartErrMsg = '都道府県、市区、町をすべて選択してください。'
            } else {
              this.chartErrMsg = '対象誘致エリアは必須入力です。'
            }
          }
        })

        if( this.isChart ) {
          this.options.xaxis.categories = []
          for( let i = 1; i <= this.barData.length; i++ ) {
            this.options.xaxis.categories.push('住所' + i)
          }
          this.chartHeight = 180 * this.barData.length + 'px'

          this.jsonErr = false
          // usonars
          axios
            .post('/v1/usonars/statistics', {
              areas_of_graph: this.barData
            })
            .then(response => {
                this.series = response.data.series
                this.areas = response.data.areas
                this.$nextTick(() => {
                  window.parent.postMessage(['loaded'], "*");
                })
              }
            )
            .catch(error => {
              this.jsonErr = true
              this.apiError = error.response.data
              }
            );
        }
      }
      this.$nextTick(() => {
        window.parent.postMessage(['loaded'], "*");
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-score {
  border-radius: .25rem;
  display: flex;
  flex-wrap: wrap;
  font-size: .95em;
  justify-content: center;
  padding: 1rem .25rem .75rem;
  & li {
    list-style-type: none;
    margin: 0 1rem .25rem;
  }
}
</style>