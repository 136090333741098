<template>
   <div class="">
     <headers />
    <div class="mb" style="padding:15px;padding-top: 70px !important;">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h1 class="page-title">{{lan.building_recommend_add}}</h1>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-12">
          <div class="tab-box" >
              <ul class="tab-head nav nav-tabs">
                <li class="nav-item" data-id="tc1" @click="tab('tc1')" v-bind:class="[curindex=='tc1' ? 'nav-item active show':'nav-item']">
                  <a data-toggle="tab" v-bind:class="[curindex=='tc1' ? 'nav-link active':'nav-link']" aria-expanded="true"><div class="lang" v-html="lan.normal_function" ></div></a>
                </li>
                <li v-if="code!=''" class="nav-item" data-id="tc2" @click="tab('tc2')" v-bind:class="[curindex=='tc2' ? 'nav-item active show':'nav-item']">
                  <a data-toggle="tab" v-bind:class="[curindex=='tc2' ? 'nav-link active':'nav-link']" aria-expanded="true"><div class="lang" v-html="lan.ai_function" ></div></a>
                </li>  
                
              </ul>
              
          </div>
        </div>
      </div>
      <div class="row">
      
        <div class="tab-content" style="width:100%;background:#fff">
        
            <div class="embed-responsive-16by9 tab-pane show active tc1" id="tc1" v-if="curindex=='tc1'">
                <div class="col-12">
                  <div class="card-box">
                    <report-list-create :apiUrl="url" returnPage="ReportList" paramKey="summary.lender.company_name" />
                  </div>
                </div>
            </div>
             <div class="embed-responsive-16by9 tab-pane show active tc2" id="tc2" v-if="curindex=='tc2'">
                <div class="col-12">
                  <div class="card-box">
                    <report-list-create-ai :apiUrl="url" returnPage="ReportList" paramKey="summary.lender.company_name" />
                  </div>
                </div>
            </div>
        </div>
      </div>
  </div>
  </div>
</template>
<script>
import ReportListCreate from '@/components/ReportListCreate.vue';
import ReportListCreateAi from '@/components/ReportListCreateAi.vue';
import router from '@/router'
import Headers from '@/components/Headers.vue'
import { inject } from 'vue';

const { currentRoute } = router;

export default {
  name: 'ReportDetail',
  components: {
    ReportListCreate,Headers,ReportListCreateAi
  },
  data() {
    return {
      curindex:'tc1',
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      cur_lan:'jp',
      url: '/v1/basic-reports/',
    }
  },
  methods: {
    tab(id) {
      this.curindex = id
    }
  },
  created() {
    if( currentRoute.value.params.dataId ) {
      this.url = '/v1/basic-reports/' + currentRoute.value.params.dataId
    }
    sessionStorage.view = 'report/add'
    sessionStorage.title = 'レポート作成'
  }
}
</script>